<template>
  <div v-if="!allowed">
    Not allowed
  </div>
  <div v-else>
    <v-card v-if="items" class="rounded-0 px-4 pt-3 pb-1">
      <FilterSearch :queryKey="'order_search'" :limitWidth="false" />
      <v-chip-group v-model="selection" mandatory active-class="primary">
        <v-chip v-for="chip in chipOptions" :key="chip.value" small>{{ chip.text }}</v-chip>
      </v-chip-group>
    </v-card>
    <MachineJobListItem :item="item" v-for="item in filteredItems" :key="item.Id" />
  </div>
</template>


<script>
import FilterSearch from "@/components/Inputs/FilterSearch.vue";
import MachineJobListItem from "@/components/ListItems/MachineJobListItem.vue";

export default {
  name: "PublicMachineJobList",
  props: {
    allowed: Boolean,
    history: Array,
  },
  components: {
    FilterSearch,
    MachineJobListItem,
  },

  data() {
    return {
      selection: null,

    };
  },

  mounted() {
  },

  methods: {
    filterBySearch(data, searchText) {
      return data.filter(x => JSON.stringify(x).includes(searchText));
    },
  },

  computed: {
    chipOptions() {
      return [
        { value: null, text: this.$t('text.all') + (this.items ? ' (' + this.items.length + ')' : '') },
        { value: 1, text: this.$t('text.open') + (this.openItems ? ' (' + this.openItems.length + ')' : '') },
        { value: 2, text: this.$t('text.closed') + (this.closedItems ? ' (' + this.closedItems.length + ')' : '') },
      ]
    },

    filteredItems() {
      switch (this.selection) {
        case 1:
          return this.openItems;
        case 2:
          return this.closedItems;
        default:
          return this.items;
      }
    },

    items() {
      var copy = Object.assign([], this.history);
      if (copy && copy.length) {
        if (this.searchword) {
          copy = this.filterBySearch(copy, this.searchword);
        }
        console.log(copy);
        return copy.reverse()
      }
      return null;
    },

    openItems() {
      var list = this.items.filter(x => x?.Job?.StatusId < 70);
      return (list && list.length) ? list : null;
    },

    closedItems() {
      var list = this.items.filter(x => x?.Job?.StatusId >= 70);
      return (list && list.length) ? list : null;
    },

    searchword() {
      return this.$route.query?.order_search;
    },
  },

  watch: {},
};
</script>


<style lang='scss'></style>